<template>
  <div class="_container">
      <h1 class="_text-center p3">Geen Toegang</h1>
  </div>
</template>

<script>
export default {

}
</script>